@tailwind base;
@tailwind components;
@tailwind utilities;

#map {
  z-index: 1;
}

* {
  touch-action: pan-y;
}